import React from 'react'
import Helmet from 'react-helmet'
import { Link } from "gatsby"
import lazysizes from 'lazysizes'
import './global.css'

import Header from '../components/header'
import Footer from '../components/footer'

export default ({ children }) => {

	// メルマガページから変遷した時に、背景を白に戻す機能
	if (typeof document !== `undefined`) {
		document.body.classList.remove('haikei-kuro')
	}

	return (
		<>

			<Helmet
				meta={[
					{
						name: `robots`,
						content: `noindex noarchive`,
					}
				]}
			/>

			<Header />

			<main class="b-center osirase seigen">

				{children}

			</main>

			<Link to='/'><div class='top_back t-center'>トップページへ戻る</div></Link>

			<Footer />

		</>
	)
}